import { notifications } from "@mantine/notifications"
import { SimpleTKey, translate } from "./textDescriptions/translate"
import errorCodeToMessage from "./textDescriptions/errorCodeToMessage"
import { TKey } from "i18n"
import { store } from "store/store"
/* eslint-disable-next-line appnflat-custom-rules/no-import-set-loading */
import { setLoading } from "store/appState"
import { ReactNode } from "react"
import { getAppLanguage } from "hooks/hooks"
import { LoadingIcon } from "components/Other/LoadingIcon"
import { LocalizedString } from "@appnflat-types/types"

type NotificationContent = Omit<Parameters<typeof notifications.show>[0], "message" | "title"> & {
    message?: SimpleTKey | LocalizedString
    /** A localized message to show in the notification, coming from the backend. */
    localizedMessage?: string
    /** If true, the notification will not close automatically. */
    important?: boolean
} & (
        | {
              /** If true, the notification will not have a title. */
              noTitle?: boolean
              title?: undefined
              customMessage?: undefined
              defaultTitle?: SimpleTKey
          }
        | {
              noTitle?: undefined | false
              title?: SimpleTKey | LocalizedString
              customMessage?: ReactNode
              defaultTitle?: SimpleTKey
          }
    )

function showNotification({
    customMessage,
    title,
    message,
    defaultTitle,
    important,
    ...params
}: NotificationContent) {
    const language = getAppLanguage()
    notifications.show({
        autoClose: important ? false : 5000,
        ...params,
        title:
            typeof title === "string" || !title ?
                translate(title ?? defaultTitle)
            :   title[language],
        message: customMessage ?? translate(message ?? "") ?? "",
    })
}

/** Shows an error notification. */
export function showErrorNotification(
    { customMessage, title, important, message, noTitle, ...params }: NotificationContent,
    error?: any
) {
    showNotification({
        color: "red",
        ...params,
        message: undefined,
        title: noTitle ? undefined : (title ?? "error"),
        customMessage: customMessage ?? errorCodeToMessage(error, message),
    })
}

/** Wrapper around `showErrorNotification` that returns a function, that when called, calls on `showErrorNotification`. */
export function errorHandlerShowNotification(params: NotificationContent) {
    return (error?: any) => {
        console.error(error)
        showErrorNotification(params, error)
    }
}

/** Shows a notification informing the user of a successful operation. */
export function showSuccessNotification(params: NotificationContent) {
    showNotification({
        color: "green",
        ...params,
        defaultTitle: "success",
    })
}

/** Wrapper around `showSuccessNotification` that returns a function, that when called, calls on `showSuccessNotification`. */
export function successHandlerShowNotification(params: NotificationContent) {
    return (result?: any) => {
        console.log(result)
        showSuccessNotification(params)
    }
}

/** Sets the global loading state. If a TKey is passed, then the state is set to loading with the given message. */
export function setLoadingState(
    state: false | ({ icon?: LoadingIcon } & ({ tkey: TKey } | { message: LocalizedString }))
) {
    store.dispatch(setLoading(state))
}
