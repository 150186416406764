import { useMemo } from "react"
import { usePermissions } from "./hooks"
import { Role } from "@constants/Role"

const buildingFinancialStaff = [Role.approver, Role.admin, Role.accountant, Role.accountantWrite]
const buildingFinancialWriteStaff = [Role.approver, Role.admin, Role.accountantWrite]
const buildingRequestsStaff = [Role.approver, Role.admin, Role.handler]

const permissionsCheck = {
    "accounts.read": buildingFinancialStaff,
    "transactions.read": [
        Role.approver,
        Role.admin,
        Role.accountant,
        Role.accountantWrite,
        Role.owner,
    ],
    "credit-notes.read.all": buildingFinancialStaff,
    "transactions.write": buildingFinancialWriteStaff,
    "people.read.all": buildingFinancialStaff,
    "people.read.self": [Role.owner, Role.resident],
    "social.read": [Role.approver, Role.admin, Role.owner, Role.resident],
    "requests.emails": buildingRequestsStaff,
    "requests.create.for_self": [Role.owner, Role.resident],
    "requests.create.for_any": [Role.approver, Role.admin, Role.handler],
    "requests.tags.edit": buildingRequestsStaff,
    "budgets.edit": buildingFinancialWriteStaff,
    "budgets.view": [Role.approver, Role.admin, Role.accountant, Role.accountantWrite, Role.owner],
    "units.close_account": buildingFinancialWriteStaff,
}

export type PermissionKey = keyof typeof permissionsCheck | undefined

/** A custom hook that returns whether the user has a given permission. */
export function useHasPermission(permissionKey: PermissionKey) {
    const { userRole } = usePermissions()

    return useMemo(() => {
        if (!permissionKey) return true
        if (!userRole) return false
        return permissionsCheck[permissionKey].includes(userRole)
    }, [permissionKey, userRole])
}
