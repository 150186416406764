import React, { forwardRef } from "react"
import { SVGProps } from "./SVGProps"

/** An svg icon of AppnflatSmall. */
export const IconAppnflatSmall = forwardRef(function IconAppnflatSmall(
    { isIcon = true, className = "", ...props }: SVGProps,
    ref: any
) {
    return <svg viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg" className={(isIcon ? "tabler-icon " : "") + className} {...props} ref={ref}>
    <path d="m0,0l512,0l0,512l-512,0l0,-512z" fill="#fff"/>
    <path
        fill="#4b67eb"
        d="m0,256 l0,256 l512,0 l0,-512 l-512,0 l0,256z m178.6,-200.6 c4.8,2 8,5.8 11.5,11.6c0.9,1.6 1.6,7 2,16.9l0.5,14l18.5,-13.7c10,-7.6 21,-15 25,-16.9c11.5,-5 28.5,-4.7 39,1.7c4,2 5,3 127.5,94.5c17,13 23,19 27.8,32c2,5.7 2,5.8 2.5,101c0,104.7 0,102 -5.6,114.6c-3.8,7.9 -13.7,17.6 -21.7,21c-3.5,1.6 -9.5,3 -13,3.9s-22.5,0.8 -41.7,0.7l-34.9,-0l-5,-14c-2.7,-7.7 -6.5,-18.7 -8.6,-24.5l-3.6,-10.5l-45.6,-0c-42.8,0 -45.7,0 -46,1.5c-0,1 -3,9 -6.5,18s-6.9,19 -8,23l-2,6.5l-34,0c-18.7,0 -37,0 -40.8,-0.7c-15,-2 -28,-12 -35,-26.6l-3.9,-8l-0,-100c0,-99.5 0,-100 2,-106.5c4,-12 10,-19 27,-31.8l8.7,-6l0,-42.7c0,-29.6 0,-43.6 1,-46c1.8,-5.5 6.8,-11 12,-13.5c4.8,-2 6,-2 26,-2s21,0 26,2"
    />
    <path
        fill="#4b67eb"
        d="m131,72c-2,2 -2,3 -2,37.6c0,19.6 0,35 0.8,35c0.5,0 11,-7.9 23.5,-17l22.7,-17l0,-18c0,-24 2,-22 -23.5,-22c-18,0 -19.6,0 -21.5,2m115.9,12c-2.5,0.7 -5,1.7 -6,2c-0.8,0.5 -16.7,12 -35,26c-18.6,14 -45.6,34 -59.9,44.8c-43,32 -44.5,33 -47.5,38c-1.6,2.5 -3,6.7 -3.9,9c-0.7,3 -1,35.5 -1,95.8c0,100 0,97 6,105.7c6.7,8.8 13,11 28,11l11,0l9,-24c5,-13 12,-31.6 15.8,-40.7s8,-22 11,-28.5c2.5,-6.6 6.5,-17 8.9,-23c2,-6 11,-29 19.9,-51.5s17.5,-45 19.8,-51l4,-10.8l25,0c19,0 25,0 25.7,1c0,0.7 5.8,15 12,31.8c6.5,16.8 14.7,37.9 18,47s8.8,22.8 11.8,30.5c2.9,7.7 10.6,27.5 17,44s14,36 17,44c3,7.7 6.9,17.8 8.7,22.5l3,8.5l13,0c13,0 13.6,0 19,-3c6.8,-3.8 12,-11 14,-18.8c0.7,-3.5 1,-34.8 0.8,-98.6l-0,-93.5l-2.8,-5c-3.5,-6.6 -1.6,-5 -80,-63.8c-41.6,-31 -66,-48.8 -68.5,-49c-6.8,-1.7 -10.6,-2 -15,-0.7"
    />
    <path fill="#4b67eb" d="M255,265 l29,70 l-58,0 Z"/>
</svg>
})
    