import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RecursiveKeyOf, GetFieldType } from "@appnflat-types/helpers"
import { TypeByCollection } from "@appnflat-types/Collection"
import { Cache, initialState, WebCacheCollections } from "./cacheHelpers"
import { CollectionAndObjectList } from "@appnflat-types/Collection"
import set from "lodash/set"
import cloneDeep from "lodash/cloneDeep"
import { IdForCollectionInput, idForCollection } from "@shared/idForCollection"
import { BuildingUser } from "@appnflat-types/BuildingUser"
import { Building } from "@appnflat-types/Building"
export {
    cachedBuildingsSelector,
    transactionsSelector,
    cachedObjectSelector,
    cachedBuildingSelector,
    cachedCollectionSelector,
    transactionPartiesSelector,
    cachedUserInBuildingSelector,
} from "./cacheSelectors"

export const cacheSlice = createSlice({
    name: "cache",
    initialState,
    reducers: {
        /** Empties the cache. */
        emptyCache: () => initialState,

        /** Removes all data from the cache related to a building. */
        emptyCacheOfBuildingData(state: Cache): Cache {
            return {
                ...initialState,
                buildings: cloneDeep(state.buildings),
            }
        },

        /**
         * Updates a field within an object (within the `edits` field). This is untyped and
         * should not be exported. Set typed version at bottom of file.
         */
        updateField<C extends WebCacheCollections>(
            state: Cache,
            action: PayloadAction<{
                /** The type of object to update. */
                collection: C
                /** The uuid of the object to update. */
                uuid: string
                /** The fiscal year the object is in. If the object has no fiscal year, this will have no impact. */
                fiscalYear: number
                /** The field to update. */
                field: string
                /** The value to set. */
                value: any
            }>
        ) {
            // @ts-ignore
            const id = idForCollection({
                collection: action.payload.collection,
                value: { uuid: action.payload.uuid, fiscalYear: action.payload.fiscalYear },
            })
            if (id)
                set(
                    state,
                    [action.payload.collection, id, "edits", action.payload.field],
                    action.payload.value
                )
        },

        /** Sets the building details for a building. */
        setBuildingFromServer(
            state: Cache,
            action: PayloadAction<{
                /** The building itself. */
                building: Building
                /** The doc `buildings/${buildingRef}/users/${userUID}`. */
                user: BuildingUser
                /** The ref of the building. */
                ref: string
            }>
        ) {
            const id = idForCollection({ collection: "buildings", value: action.payload.building })
            if (id) {
                set(state, ["buildings", id, "original"], action.payload.building)
                set(state, ["buildings", id, "user"], action.payload.user)
                set(state, ["buildings", id, "ref"], action.payload.ref)
            }
        },

        /** Sets the `original` field for an object. */
        setObjectFromServer(state: Cache, action: PayloadAction<IdForCollectionInput>) {
            const id = idForCollection(action.payload)
            if (id) set(state, [action.payload.collection, id, "original"], action.payload.value)
        },

        /** Sets a collection. Will just update the `original` field in the entries. */
        setCollectionFromServer(
            state: Cache,
            action: PayloadAction<
                CollectionAndObjectList & {
                    /** Whether to replace all values for the given collection by the new values. */
                    removeAllCurrentValues: boolean
                }
            >
        ) {
            if (action.payload.removeAllCurrentValues) set(state, action.payload.collection, {})
            for (let i = 0, n = action.payload.values.length; i < n; i++) {
                const value = action.payload.values[i]
                if (!value) continue
                // @ts-ignore
                const id = idForCollection({ collection: action.payload.collection, value })
                if (id) set(state, [action.payload.collection, id, "original"], value)
            }
        },
    },
})

export const {
    emptyCache,
    setObjectFromServer,
    setBuildingFromServer,
    setCollectionFromServer,
    emptyCacheOfBuildingData,
} = cacheSlice.actions

/**
 * Updates a field within an object (within the `edits` field). This is the typed version of
 * `_updateField`.
 */
export function updateField<
    C extends WebCacheCollections,
    F extends RecursiveKeyOf<TypeByCollection[C]>,
>(action: {
    /** The type of object to update. */
    collection: C
    /** The uuid of the object to update. */
    uuid: string
    /** The fiscal year the object is in. If the object has no fiscal year, this will have no impact. */
    fiscalYear: number
    /** The field to update. */
    field: F
    /** The value to set. */
    value: GetFieldType<TypeByCollection[C], F>
}) {
    return cacheSlice.actions.updateField(action)
}
