import { useAppParams, useAppSelector, useAppTranslation } from "hooks/hooks"
import { useMemo } from "react"
import { WebCacheCollections } from "store/cacheHelpers"
import {
    cachedCollectionSelector,
    cachedObjectSelector,
    StoreArchived,
    transactionPartiesSelector,
    TransactionPartiesSelectorCollection,
} from "store/cacheSelectors"
import { RecursiveKeyOf } from "@appnflat-types/helpers"
import get from "lodash/get"
import { Unit } from "@appnflat-types/Unit"
import { Person } from "@appnflat-types/Person"
import personName from "@shared/personName"
import { Supplier } from "@appnflat-types/Supplier"
import { TypeByCollection } from "@appnflat-types/Collection"
import { RequestTag } from "@appnflat-types/Request"
import { Bank } from "@appnflat-types/Bank"
import { idForCollection, IdForCollectionInput } from "@shared/idForCollection"
import { GetFieldType, RecursiveKeysOfUnion } from "@appnflat-types/helpers"
import { cachedBuildingSelector } from "store/cacheSelectors"
import { userDocsInBuildingsSelector, userSelector } from "store/appState"
import { GlobalUser } from "@appnflat-types/GlobalUser"
import { BuildingUser } from "@appnflat-types/BuildingUser"

/** Returns the current building. */
export function useBuilding() {
    const { buildingRef } = useAppParams()
    const building = useAppSelector((s) => cachedBuildingSelector(s, buildingRef))
    return building
}

/** Returns the currency of the current building. */
export function useCurrency() {
    const building = useBuilding()
    return building?.currency ?? "CAD"
}

/** Returns the user and the user in the current building. */
export function useUser(kind: "global"): GlobalUser | undefined
export function useUser(kind: "building"): BuildingUser | undefined
export function useUser(kind?: "all"): {
    globalUser: GlobalUser | undefined
    buildingUser: BuildingUser | undefined
}
export function useUser(kind: "global" | "building" | "all" = "all") {
    const { buildingRef } = useAppParams()
    const globalUser = useAppSelector(userSelector)
    const userDocsInBuildings = useAppSelector(userDocsInBuildingsSelector)
    const buildingUser = useMemo(() => {
        if (!buildingRef) return undefined
        return userDocsInBuildings[buildingRef]
    }, [userDocsInBuildings, buildingRef])
    if (kind === "global") return globalUser
    if (kind === "building") return buildingUser
    return { globalUser, buildingUser }
}

/** Returns a property of the user. */
export function useUserProperty<K extends keyof BuildingUser | keyof GlobalUser>(property: K) {
    const user = useUser()
    return useMemo(() => {
        return get(user.globalUser, property) ?? get(user.buildingUser, property)
    }, [user, property])
}

/**
 * Returns a list of objects from the cache.
 *
 * @param collection - The collection to return.
 * @param includeArchived - Whether to include or exclude archived objects.
 * @param fiscalYear - If specified, object that contain a fiscalYear field and that
 * are not from the given fiscal year will be filtered out.
 */
export function useCollection<C extends WebCacheCollections>(
    collection: C,
    includeArchived: StoreArchived = StoreArchived.include,
    filterByFiscalYear: boolean = true
) {
    const { fiscalYear } = useAppParams()
    return useAppSelector((state) =>
        cachedCollectionSelector(
            state,
            collection,
            includeArchived,
            filterByFiscalYear ? fiscalYear : undefined
        )
    )
}

type IdForCollectionInputFilter<C extends IdForCollectionInput["collection"]> =
    IdForCollectionInput extends infer O ?
        O extends { collection: infer CO } ?
            C extends CO ?
                O
            :   never
        :   never
    :   never

/** Returns an object from the cache by identifier.
 *
 * @param collection - The collection in which to look for the object.
 * @param idParams - The parameters to use to get the identifier. If `true`, will return
 * `undefined`.
 */
export function useObject<C extends WebCacheCollections>(
    collection: C,
    idParams: IdForCollectionInputFilter<C>["value"] | true
) {
    const id = idParams === true ? null : idForCollection({ collection, value: idParams } as any)
    return useAppSelector((state) => cachedObjectSelector(state, collection, id))
}

/** Returns a field from an object from the cache by identifier.
 *
 * @param collection - The collection in which to look for the object.
 * @param idParams - The parameters to use to get the identifier. If `true`, will return
 * `undefined`.
 * @param field - The field to return from the object.
 */
export function useObjectField<
    C extends WebCacheCollections,
    F extends RecursiveKeysOfUnion<TypeByCollection[C]> & string,
>(collection: C, idParams: IdForCollectionInputFilter<C>["value"] | true, field: F) {
    const object = useObject(collection, idParams) as any
    return useMemo(() => {
        if (!object || !field) return undefined
        return get(object, field) as GetFieldType<TypeByCollection[C], F>
    }, [object, field])
}

/**
 * Returns a list of transaction parties from the cache.
 * @param collections - The collections to include.
 *   - `all` means all unarchived accounts (all accounts for which a new transaction can be created),
 *   - `all-including-archived` means all accounts (useful to display transaction with potentially archived accounts),
 *   - `bank-accounts` means excluding investment and saving accounts.
 *   - `banks-otonom` means bank accounts that can be used in Otonom transactions (currently, only
 *   the bank account specified as `defaultBankAccountAID` in the building doc).
 */
export function useTransactionParties(collections: TransactionPartiesSelectorCollection) {
    const { buildingRef, fiscalYear } = useAppParams()
    return useAppSelector((state) =>
        transactionPartiesSelector(state, collections, fiscalYear, buildingRef)
    )
}

type UseSelectOptionsCollections = WebCacheCollections &
    ("units" | "suppliers" | "banks" | "people" | "requestTags")

/**
 * Returns a preformatted list of objects to be used in WSelect or WMultiSelect.
 * @param collection - The collection to return.
 * @param valueField - The field to use for the value of a select option. If left empty,
 * the field used will be `uuid`.
 * @param includeArchived - Whether to include or exclude archived objects. (default: include)
 */
export function useSelectOptions<C extends UseSelectOptionsCollections>(
    collection: C,
    valueField: RecursiveKeyOf<TypeByCollection[C]>,
    includeArchived: StoreArchived = StoreArchived.include
) {
    const objects = useCollection(collection, includeArchived, true)
    const t = useAppTranslation(localTranslations)

    const labelGenerator = useMemo(
        () =>
            collection === "units" ?
                (obj: Unit) =>
                    obj.soldAndNeedToSetBalanceToZero || obj.archived ?
                        t("core:unit_NUMBER_sold", { number: obj.aid })
                    :   t("core:unit_k", { number: obj.number ?? obj.aid })
            : collection === "banks" ? (obj: Bank) => obj.name ?? obj.aid
            : collection === "suppliers" ? (obj: Supplier) => obj.name ?? obj.aid
            : collection === "people" ?
                (obj: Person) =>
                    obj.archived ?
                        t("$personArchived", { $person: personName(obj) })
                    :   personName(obj)
            : collection === "requestTags" ? (obj: RequestTag) => obj.name
            : (obj: Person | Supplier | Unit | RequestTag) => obj.uuid,
        [collection, t]
    )

    return useMemo(() => {
        if (!labelGenerator) return []
        return objects.map((obj) => ({
            value: get(obj, valueField) as string,
            label: labelGenerator(obj as any),
            color: "color" in obj ? obj.color : undefined,
        }))
    }, [labelGenerator, objects, valueField])
}

const localTranslations = {
    $personArchived: {
        en: "$person (archived)",
        fr: "$person (archivée)",
    },
}
