import { z } from "zod"
import { buildingRefString } from "./BaseStrings"
import { emailFrequencySchema } from "./Common"

/** The parameters to determine the id of a notification.
 *
 * WARNING: do not include any PII (emails, names, etc.) in this, as it will be sent to Sendgrid,
 * which doesn't treat it as PII, and also used as document ids in Firestore, which is similarly
 * do not treat it as PII. */
export const notificationIdSchema = z.discriminatedUnion("type", [
    z.object({
        /** - `notifyRecipientsOfPost`: notify the recipients of a post. */
        type: z.literal("notifyRecipientsOfPost"),
        buildingRef: buildingRefString,
        postUUID: z.string(),
    }),
    z.object({
        /** - `waterHeaterExpiration`: the water heater is about to expire.
         *  - `waterHeaterInfoMissing`: the water heater information is missing.
         *  - `insuranceExpiration`: the insurance is about to expire.
         *  - `insuranceInfoMissing`: the insurance information is missing.
         *  - `unpaidBalance`: the tenant has an unpaid balance. */
        type: z.enum([
            "waterHeaterExpiration",
            "waterHeaterInfoMissing",
            "insuranceExpiration",
            "insuranceInfoMissing",
            "unpaidBalance",
        ]),
        buildingRef: buildingRefString,
        unitUUID: z.string(),
        /** The date of the event, as an ISO string. */
        date: z.string().date(),
        frequency: emailFrequencySchema.exclude(["never"]),
    }),
    z.object({
        /** - `pendingTransactionApproval`: a transaction needs to be approved. */
        type: z.literal("pendingTransactionApproval"),
        buildingRef: buildingRefString,
        transactionUUID: z.string(),
        reminderDay: z.string().date(),
    }),
    z.object({
        /** - `newCheckSavedForUnit`: a new check was saved for a unit. */
        type: z.literal("newCheckSavedForUnit"),
        buildingRef: buildingRefString,
        unitUUID: z.string(),
        /** The UUID of the check. */
        checkUUID: z.string(),
    }),
    z.object({
        /** - `unitReversedPayment`: a payment was reversed for a unit.
         *  - `unitBillbackCreated`: a billback was created for a unit. */
        type: z.enum(["unitReversedPayment", "unitBillbackCreated"]),
        buildingRef: buildingRefString,
        unitUUID: z.string(),
        transactionUUID: z.string(),
    }),
    z.object({
        /** - `requestEmail`: a request email being sent by a user.
         *  - `requestEmailReply`: a reply to a request email. */
        type: z.enum(["requestEmail", "requestEmailReply"]),
        buildingRef: buildingRefString,
        /** The UUID of the request. */
        requestUUID: z.string(),
        /** The UUID of the email. */
        requestEmailUUID: z.string(),
    }),
    z.object({
        /** - `emailVerificationCode`: a code to verify an email. */
        type: z.literal("emailVerificationCode"),
        /** The uuid of the event, randomly constructed. */
        uuid: z.string(),
    }),
    z.object({
        /** - `passwordResetCode`: a code to reset a password. */
        type: z.literal("userAddedToBuilding"),
        buildingRef: buildingRefString,
        /** The UID of the user. */
        userUID: z.string(),
    }),
    z.object({
        /** - `userInvitedToBuilding`: a user is invited to a building. */
        type: z.literal("userInvitedToBuilding"),
        buildingRef: buildingRefString,
        /** The uuid of the event, randomly constructed. */
        uuid: z.string(),
    }),
    z.object({
        /** - `unitStatement`: a statement for a unit. */
        type: z.literal("unitStatement"),
        unitUUID: z.string(),
        buildingRef: buildingRefString,
        day: z.string().date(),
    }),
    z.object({
        /** - `automatedAlert`: an automated alert */
        type: z.literal("automatedAlert"),
        buildingRef: buildingRefString,
        /** The UUID of the alert. */
        uuid: z.string(),
    }),
])
export type NotificationId = z.infer<typeof notificationIdSchema>

/** A document in the `notificationsSent` collection storing the last time a specific notification was sent. */
export const notificationSentSchema = z.object({
    notificationId: notificationIdSchema,
    /** The list of emails the notification was already sent to. */
    sentTo: z.array(z.string()),
})
export type NotificationSent = z.infer<typeof notificationSentSchema>
