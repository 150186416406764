import z from "zod"
import { uidString, uuidString } from "./BaseStrings"

/** A log event. */
export enum LogEvent {
    /** A new transaction is created. */
    TransactionAdded = "TransactionAdded",
    /** A transaction is canceled. */
    TransactionCanceled = "TransactionCanceled",
    /** An automated system added recurring bills and / or payments. */
    RecurringBillAdded = "RecurringBillAdded",
    /** An automated system added a payment transaction for some units. */
    UnitAutomatedPaymentAdded = "UnitAutomatedPaymentAdded",
}

/** A log entry. */
export const logSchema = z.object({
    event: z.nativeEnum(LogEvent),
    date: z.number(),
    uuid: uuidString,
    caller: z.discriminatedUnion("type", [
        z.object({
            type: z.literal("user"),
            user: uidString,
        }),
        z.object({
            type: z.literal("system"),
            system: z.enum(["backend", "cloud-functions"]),
        }),
    ]),
    message: z.string().max(100000).optional(),
})
/** A log entry. */
export type Log = z.infer<typeof logSchema>
