import React, { useCallback } from "react"
import { Modal } from "@mantine/core"
import { useAppTranslation } from "hooks/hooks"
import { auth } from "firebaseSetup"
import { sendPasswordResetEmail } from "firebase/auth"
import { Button } from "@mantine/core"
import { WTextInput } from "components/Inputs/WTextInput"
import { useForm } from "hooks/useForm"
import { z } from "zod"
import { useDisclosure } from "@mantine/hooks"
import {
    setLoadingState,
    showErrorNotification,
    showSuccessNotification,
} from "logic/notifications"

const resetPasswordFormSchema = z.object({
    email: z.string().email(),
})

export default function ResetPasswordPopup() {
    const t = useAppTranslation(localTranslations)
    const [show, { open, close }] = useDisclosure(false)

    const onSubmit = useCallback(
        (data: z.infer<typeof resetPasswordFormSchema>) => {
            close()
            setLoadingState({ message: localTranslations.sending })
            sendPasswordResetEmail(auth, data.email)
                .then(() => {
                    showSuccessNotification({
                        message: localTranslations.clickOnLink,
                        autoClose: false,
                    })
                    setLoadingState(false)
                })
                .catch((error) => {
                    showErrorNotification({ message: localTranslations.unableToSend }, error)
                    setLoadingState(false)
                })
        },
        [close]
    )

    const form = useForm(resetPasswordFormSchema, onSubmit)

    return (
        <>
            <Button
                variant="transparent"
                fullWidth
                component="button"
                size="xs"
                onClick={open}
                color="gray"
            >
                {t("forgotPassword")}
            </Button>

            <Modal opened={show} onClose={close} title={t("resetPassword")}>
                <WTextInput
                    label="core:email"
                    editing
                    variant="default"
                    {...form.propsForWTextInput("email")}
                />

                <Button mt="md" fullWidth onClick={form.submit}>
                    {t("sendLinkToResetPassword")}
                </Button>
            </Modal>
        </>
    )
}

const localTranslations = {
    forgotPassword: {
        en: "Forgot password",
        fr: "Mot de passe oublié",
    },
    resetPassword: {
        en: "Reset password",
        fr: "Réinitialiser le mot de passe",
    },
    sendLinkToResetPassword: {
        en: "Send link to reset password",
        fr: "Envoyer un lien pour réinitialiser le mot de passe",
    },
    sending: {
        en: "Sending reset link...",
        fr: "Envoi du lien de réinitialisation...",
    },
    unableToSend: {
        en: "We were unable to send an email to the email you gave us.",
        fr: "Nous n'avons pas été en mesure de vous envoyer un courriel à l'adresse que vous nous avez communiquée.",
    },
    clickOnLink: {
        en: "Please click on the link sent to your email to reset your password.",
        fr: "Veuillez cliquer sur le lien envoyé à votre adresse courriel afin de réinitialiser votre mot de passe.",
    },
}
