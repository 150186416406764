import {
    z,
    ZodArray,
    ZodDiscriminatedUnion,
    ZodEffects,
    ZodNullable,
    ZodNumber,
    ZodNumberCheck,
    ZodObject,
    ZodOptional,
    ZodString,
    ZodTypeAny,
    ZodUnion,
} from "zod"
import { RecursiveKeysOfUnion } from "./helpers"

type AddOpenAPIParams = {
    type?: "string"
    pattern?: string
    maxLength?: number
    minLength?: number
}

/** Calls the `.openapi()` function on a zod object if it exists, skips calling it otherwise. */
export function addOpenAPI<Z extends z.ZodType>(toExtend: Z, params: AddOpenAPIParams): Z {
    return "openapi" in toExtend && typeof toExtend.openapi === "function" ?
            toExtend.openapi(params)
        :   toExtend
}

/**
 * Creates a custom zod object with regex that supports both the OpenAPI field and a template
 * literal custom TypeScript type.
 *
 * @example
 * // Creates a zod object with type `unit:${number}`. When used in an OpenAPI schema, it will
 * // have the following properties:
 * // - type: "string"
 * // - pattern: "^unit:[0-9]+$"
 * zodRegex<`unit:${number}`>(/^unit:[0-9]+$/)
 */
export function zodRegex<TemplateLiteral extends string>(
    pattern: RegExp,
    { message, max, min }: { message?: string; max?: number; min?: number } = {}
) {
    const zodType = z
        .custom<TemplateLiteral>(
            (v) =>
                typeof v === "string" &&
                pattern.test(v) &&
                (!max || v.length <= max) &&
                (!min || v.length >= min),
            { message }
        )
        .and(z.string())

    return addOpenAPI(zodType, {
        type: "string",
        maxLength: max,
        minLength: min,
        pattern: pattern.source,
    })
}

/** Returns the maximum and minimum values for a field. */
export function limitsOfField<T extends ZodTypeAny>(
    schema: T,
    field: RecursiveKeysOfUnion<z.infer<T>>
): { min: number | undefined; max: number | undefined } {
    return limitsOfFieldInternal(schema, field.split("."))
}
/** Returns the maximum and minimum values for a field. */
function limitsOfFieldInternal<T extends ZodTypeAny>(
    schema: T,
    fields: string[]
): { min: number | undefined; max: number | undefined } {
    try {
        const field = fields[0]
        if (schema instanceof ZodEffects) {
            return limitsOfFieldInternal(schema.innerType(), fields)
        } else if (schema instanceof ZodNullable || schema instanceof ZodOptional) {
            return limitsOfFieldInternal(schema.unwrap(), fields)
        } else if (schema instanceof ZodNumber || schema instanceof ZodString) {
            return limitsOfFieldNumberOrString(schema)
        } else if (schema instanceof ZodArray && field && /\[\d+\]/.test(field)) {
            return limitsOfFieldInternal(schema.element, fields.slice(1))
        } else if (schema instanceof ZodObject && field) {
            return limitsOfFieldInternal(schema.shape[field], fields.slice(1))
        } else if (schema instanceof ZodUnion || schema instanceof ZodDiscriminatedUnion) {
            const limits: { min: number | undefined; max: number | undefined }[] =
                schema.options.map((option: any) => limitsOfFieldInternal(option, fields))
            const min = Math.min(...limits.map((limit) => limit.min ?? -Infinity))
            const max = Math.max(...limits.map((limit) => limit.max ?? Infinity))
            return {
                min: min === -Infinity ? undefined : min,
                max: max === Infinity ? undefined : max,
            }
        } else {
            return limitsOfFieldNumberOrString(schema as any)
        }
    } catch {
        return { min: undefined, max: undefined }
    }
}

function isZodNumberCheckMinMax(check: unknown): check is ZodNumberCheck & { kind: "min" | "max" } {
    return (
        typeof check === "object" &&
        !!check &&
        "kind" in check &&
        (check.kind === "min" || check.kind === "max") &&
        "value" in check &&
        typeof check.value === "number"
    )
}

function limitsOfFieldNumberOrString<T extends ZodNumber | ZodString>(
    schema: T
): { min: number | undefined; max: number | undefined } {
    if (
        !schema ||
        !schema._def ||
        !("checks" in schema._def) ||
        !schema._def.checks ||
        !Array.isArray(schema._def.checks)
    ) {
        return { min: undefined, max: undefined }
    }
    const min = schema._def.checks.find(
        (check: unknown): check is ZodNumberCheck & { kind: "min" } =>
            isZodNumberCheckMinMax(check) && check.kind === "min"
    )?.value
    const max = schema._def.checks.find(
        (check: unknown): check is ZodNumberCheck & { kind: "max" } =>
            isZodNumberCheckMinMax(check) && check.kind === "max"
    )?.value
    return { min, max }
}

/** Returns whether a field is required or not. */
export function isRequiredField<T extends ZodTypeAny>(schema: T, field: string): boolean {
    try {
        const fields = field.split(".")
        return isRequiredFieldInternal(schema, fields)
    } catch {
        return false
    }
}

function isRequiredFieldInternal(schema: ZodTypeAny, fields: string[]): boolean {
    if (!schema) {
        return false
    } else if (schema instanceof z.ZodOptional) {
        return false
    } else if (schema._def?.effect?.type === "transform") {
        return isRequiredFieldInternal(schema._def.schema, fields)
    } else if (schema instanceof ZodUnion || schema instanceof ZodDiscriminatedUnion) {
        // If any option makes it optional, the field is optional
        return schema.options.every((option: any) => isRequiredFieldInternal(option, fields))
    } else if (schema instanceof ZodObject) {
        const [currentField, ...remainingFields] = fields
        if (!currentField) return true
        if (!schema.shape[currentField]) return false
        return isRequiredFieldInternal(schema.shape[currentField], remainingFields)
    }

    // Base case - schema exists and isn't optional
    return true
}
