/**************************************
 * CACHE SELECTORS
 **************************************/

import { TypeByCollection } from "@appnflat-types/Collection"
import { type RootState } from "./store"
import { translate } from "logic/textDescriptions/translate"
import { WSelectOption } from "components/Inputs/WSelect"
import { Bank, BankAccountType, bankAccountTypeSchema } from "@appnflat-types/Bank"
import { Unit } from "@appnflat-types/Unit"
import { Supplier } from "@appnflat-types/Supplier"
import { Transaction } from "@appnflat-types/Transaction"
import { WebCacheCollections, mergeEntry } from "./cacheHelpers"
import { Category } from "@appnflat-types/Category"
import { Building } from "@appnflat-types/Building"
import { BuildingUser } from "@appnflat-types/BuildingUser"
import { createSelectorCreator, weakMapMemoize } from "reselect"
import { AidString } from "@appnflat-types/BaseStrings"

const createSelectorWeakMap = createSelectorCreator({
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
})

export enum StoreArchived {
    include,
    exclude,
}

/** Returns the list of buildings from the cache. */
export const cachedBuildingsSelector = createSelectorWeakMap(
    (state: RootState) => state.cache.buildings,
    (buildings) => {
        const keys = Object.keys(buildings)
        const values: {
            building: Building
            /** The user's document in the building. */
            user: BuildingUser
        }[] = []
        for (let i = 0, n = keys.length; i < n; i++) {
            const key = keys[i]
            if (!key) continue
            const entry = buildings[key]
            if (!entry) continue
            const building = mergeEntry<"buildings">(entry)
            values.push({ building, user: entry.user })
        }
        return values
    }
)

/** Returns a list of objects from the cache.
 * @param collection - The collection to return.
 * @param includeArchived - Whether to include or exclude archived objects.
 * @param fiscalYear - If specified, object that contain a fiscalYear field and that
 * are not from the given fiscal year will be filtered out.
 */
export const cachedCollectionSelector: <C extends WebCacheCollections>(
    state: RootState,
    collection: C,
    includeArchived: StoreArchived,
    fiscalYear?: number
) => TypeByCollection[C][] = createSelectorWeakMap(
    [
        (state: RootState, collection: WebCacheCollections) => state.cache[collection],
        (__state: RootState, __collection: WebCacheCollections, includeArchived: StoreArchived) =>
            includeArchived,
        (
            __state: RootState,
            __collection: WebCacheCollections,
            __includeArchived: StoreArchived,
            fiscalYear?: number
        ) => fiscalYear,
    ],
    (collectionInCache, includeArchived, fiscalYear): TypeByCollection[WebCacheCollections][] => {
        const ia = includeArchived === StoreArchived.include
        const entries = Object.values(collectionInCache)
        const values: TypeByCollection[WebCacheCollections][] = []
        for (let i = 0, n = entries.length; i < n; i++) {
            const entry = entries[i]
            if (!entry) continue
            const value = mergeEntry<WebCacheCollections>(entry)
            if (
                !value ||
                (fiscalYear && "fiscalYear" in value && value.fiscalYear !== fiscalYear) ||
                (!ia && "archived" in value && value.archived)
            )
                continue
            values.push(value)
        }
        return values
    }
) as any

type CachedObjectSelector = <C extends WebCacheCollections>(
    state: RootState,
    collection: C,
    id: string | null
) => TypeByCollection[C] | undefined

/** Returns an object from the cache by identifier.
 *
 * @param collection - The collection in which to look for the object.
 * @param id - The identifier of the object to return. If `null`, will return `undefined`.
 */
export const cachedObjectSelector: CachedObjectSelector = createSelectorWeakMap(
    [
        (state: RootState, collection: WebCacheCollections, id: string | null) =>
            !id ? undefined : state.cache[collection][id],
    ],
    (obj) => {
        if (!obj) return undefined
        return mergeEntry<WebCacheCollections>(obj)
    }
) as CachedObjectSelector

/** Returns the current building. */
export const cachedBuildingSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.buildings,
        (__: RootState, buildingRef: string | undefined) => buildingRef,
    ],
    (buildings, buildingRef) => {
        if (!buildingRef) return undefined
        const building = Object.entries(buildings).find(
            ([, entry]) => entry.ref === buildingRef
        )?.[1]
        if (!building) return undefined
        return mergeEntry<"buildings">(building)
    }
)

/** Returns the user's document for the current building. */
export const cachedUserInBuildingSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.buildings,
        (__: RootState, buildingRef: string | undefined) => buildingRef,
    ],
    (buildings, buildingRef) => {
        if (!buildingRef) return undefined
        return Object.entries(buildings).find(([, entry]) => entry.ref === buildingRef)?.[1].user
    }
)

export type TransactionPartiesSelectorCollection =
    | "all"
    | "all-including-archived"
    | (
          | "units"
          | "banks"
          | "suppliers"
          | "categories"
          | `categories-${Exclude<Category["parent"], undefined>}`
          | `banks-${Exclude<Bank["bankAccountType"], undefined>}`
          | "banks-otonom"
      )[]

/** Returns a list of possible transaction parties.
 *
 * Will not include archived accounts (except for `units` which have
 * `soldAndNeedToSetBalanceToZero` set to true, since new transactions can be
 * created for those, and for `all-including-archived`).
 * @param collections - The collections to include.
 *   - `all` means all unarchived accounts (all accounts for which a new transaction can be created),
 *   - `all-including-archived` means all accounts (useful to display transaction with potentially archived accounts),
 *   - `bank-accounts` means excluding investment and saving accounts.
 *   - `banks-otonom` means bank accounts that can be used in Otonom transactions (currently, only
 *   the bank account specified as `defaultBankAccountAID` in the building doc).
 */
export const transactionPartiesSelector = createSelectorWeakMap(
    [
        (state: RootState, collections: TransactionPartiesSelectorCollection) =>
            (
                collections === "all" ||
                collections === "all-including-archived" ||
                collections.some((c) => c.startsWith("banks"))
            ) ?
                state.cache.banks
            :   undefined,
        (state: RootState, collections: TransactionPartiesSelectorCollection) =>
            (
                collections === "all" ||
                collections === "all-including-archived" ||
                collections.includes("suppliers")
            ) ?
                state.cache.suppliers
            :   undefined,
        (state: RootState, collections: TransactionPartiesSelectorCollection) =>
            (
                collections === "all" ||
                collections === "all-including-archived" ||
                collections.includes("units")
            ) ?
                state.cache.units
            :   undefined,
        (state: RootState, collections: TransactionPartiesSelectorCollection) =>
            (
                collections === "all" ||
                collections === "all-including-archived" ||
                collections.some((c) => c.startsWith("categories"))
            ) ?
                state.cache.categories
            :   undefined,
        (
            state: RootState,
            __c: TransactionPartiesSelectorCollection,
            __f: number | undefined,
            buildingRef: string | undefined
        ) => {
            if (!buildingRef) return undefined
            return state.cache.buildings[buildingRef]
        },
        (__: RootState, collections: TransactionPartiesSelectorCollection) => collections,
        (
            __: RootState,
            __c: TransactionPartiesSelectorCollection,
            fiscalYear: number | undefined
        ) => fiscalYear,
    ],
    (banks, suppliers, units, categories, building, collections, fiscalYear) => {
        const results: WSelectOption<AidString>[] = []
        const includeAllCollections =
            collections === "all" || collections === "all-including-archived"
        const includeArchived = collections === "all-including-archived"

        const banksList = banks ? Object.values(banks).map((b) => mergeEntry<"banks">(b)) : []
        const suppliersList = suppliers ? Object.values(suppliers) : []
        const unitsList = units ? Object.values(units) : []
        const categoriesList =
            categories ? Object.values(categories).map((c) => mergeEntry<"categories">(c)) : []
        const mergedBuilding = building ? mergeEntry<"buildings">(building) : undefined
        function excludeAccount(account: Supplier | Unit | Bank | Category, isUnit?: boolean) {
            return (
                account.fiscalYear !== fiscalYear ||
                (!includeArchived &&
                    account.archived &&
                    (!isUnit ||
                        !("soldAndNeedToSetBalanceToZero" in account) ||
                        !account.soldAndNeedToSetBalanceToZero))
            )
        }
        if (includeAllCollections || collections.some((c) => c.startsWith("banks"))) {
            const types = new Set(
                Array.isArray(collections) ?
                    collections
                        .map((c) => c.startsWith("banks-") && c.split("-")[1])
                        .filter(
                            (c): c is BankAccountType => bankAccountTypeSchema.safeParse(c).success
                        )
                :   []
            )
            const includeAllTypes = includeAllCollections || collections.includes("banks")
            const includeOtonom = includeAllCollections || collections.includes("banks-otonom")
            const otonomAID = mergedBuilding?.defaultBankAccountAID
            for (let i = 0, n = banksList.length; i < n; i++) {
                const obj = banksList[i]
                if (
                    !obj ||
                    excludeAccount(obj) ||
                    (!includeAllTypes &&
                        (!obj.bankAccountType || !types.has(obj.bankAccountType)) &&
                        (!includeOtonom || obj.aid !== otonomAID))
                )
                    continue
                results.push({
                    value: obj.aid,
                    label: obj.name ? `${obj.aid} - ${obj.name}` : obj.aid,
                    group: "core:banks",
                })
            }
        }
        if (includeAllCollections || collections.some((c) => c.startsWith("categories"))) {
            const types = new Set(
                Array.isArray(collections) ?
                    collections
                        .map((c) => c.startsWith("categories-") && c.split("-")[1])
                        .filter((c): c is string => !!c)
                :   []
            )
            const includeAllTypes = includeAllCollections || collections.includes("categories")
            for (let i = 0, n = categoriesList.length; i < n; i++) {
                const obj = categoriesList[i]
                if (
                    !obj ||
                    excludeAccount(obj) ||
                    (!includeAllTypes && (!obj.parent || !types.has(obj.parent)))
                )
                    continue
                results.push({
                    value: obj.aid,
                    label: obj.name ? `${obj.aid} - ${obj.name}` : obj.aid,
                    group: "core:accounting_categories",
                })
            }
        }
        if (includeAllCollections || collections.includes("suppliers")) {
            for (let i = 0, n = suppliersList.length; i < n; i++) {
                const entry = suppliersList[i]
                if (!entry) continue
                const obj = mergeEntry<"suppliers">(entry)
                if (excludeAccount(obj)) continue
                results.push({
                    value: obj.aid,
                    label: obj.name ? `${obj.aid} - ${obj.name}` : obj.aid,
                    group: "core:suppliers",
                })
            }
        }
        if (includeAllCollections || collections.includes("units")) {
            for (let i = 0, n = unitsList.length; i < n; i++) {
                const entry = unitsList[i]
                if (!entry) continue
                const obj = mergeEntry<"units">(entry)
                if (excludeAccount(obj, true)) continue
                results.push({
                    value: obj.aid,
                    label:
                        obj.soldAndNeedToSetBalanceToZero || obj.archived ?
                            translate("unit_NUMBER_sold", { number: obj.aid })
                        :   translate("unit_k", { number: obj.number ?? obj.aid }),
                    group: "core:units",
                })
            }
        }
        return results
    }
)

/** Returns a list of transactions from the cache.
 *
 * @param fiscalYear - The fiscal year to filter the transactions by.
 * @param includeCancelled - Whether to include cancelled transactions. (default: true)
 */
export const transactionsSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.transactions,
        (state: RootState) => state.cache.unreconciledTransactions,
        (__: RootState, fiscalYear: number | undefined) => fiscalYear,
        (__: RootState, __f: number | undefined, includeCancelled: boolean | undefined) =>
            includeCancelled,
    ],
    (transactions, unreconciledTransactions, fiscalYear, includeCancelled) => {
        const keysTransactions = Object.keys(transactions)
        const keysUnreconciledTransactions = Object.keys(unreconciledTransactions)
        const values: {
            transaction: Transaction
            collection: "transactions" | "unreconciledTransactions"
        }[] = []
        for (let i = 0, n = keysTransactions.length; i < n; i++) {
            const key = keysTransactions[i]
            if (!key) continue
            const entry = transactions[key]
            if (!entry) continue
            const transaction = mergeEntry<"transactions">(entry)
            if (
                !transaction ||
                transaction.fiscalYear !== fiscalYear ||
                (transaction.status === "cancelled" && includeCancelled === false)
            )
                continue
            values.push({ transaction, collection: "transactions" as const })
        }
        for (let i = 0, n = keysUnreconciledTransactions.length; i < n; i++) {
            const key = keysUnreconciledTransactions[i]
            if (!key) continue
            const entry = unreconciledTransactions[key]
            if (!entry) continue
            const transaction = mergeEntry<"unreconciledTransactions">(entry)
            if (
                !transaction ||
                transaction.fiscalYear !== fiscalYear ||
                (transaction.status === "cancelled" && includeCancelled === false)
            )
                continue
            values.push({ transaction, collection: "unreconciledTransactions" as const })
        }
        return values
    }
)

export type AIDToCollectionAndUUID = Record<
    AidString,
    { collection: "suppliers" | "units" | "banks" | "categories"; uuid: string }
>

/** Returns a map of aids to collection. */
export const cacheAidToCollectionSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.banks,
        (state: RootState) => state.cache.units,
        (state: RootState) => state.cache.categories,
        (state: RootState) => state.cache.suppliers,
    ],
    (banks, units, categories, suppliers): AIDToCollectionAndUUID => {
        const values: AIDToCollectionAndUUID = {}
        Object.values(banks).forEach((b) => {
            const v = mergeEntry<"banks">(b)
            values[v.aid] = { collection: "banks", uuid: v.uuid }
        })
        Object.values(categories).forEach((c) => {
            const v = mergeEntry<"categories">(c)
            values[v.aid] = { collection: "categories", uuid: v.uuid }
        })
        Object.values(suppliers).forEach((s) => {
            const v = mergeEntry<"suppliers">(s)
            values[v.aid] = { collection: "suppliers", uuid: v.uuid }
        })
        Object.values(units).forEach((u) => {
            const v = mergeEntry<"units">(u)
            values[v.aid] = { collection: "units", uuid: v.uuid }
        })
        return values
    }
)
