import { useEffect, useMemo, useState } from "react"
import { db } from "../firebaseSetup"
import {
    collection,
    query,
    where,
    QueryConstraint,
    getCountFromServer,
    orderBy,
} from "firebase/firestore"
import { useAppParams } from "hooks/hooks"
import { PermissionKey, useHasPermission } from "./useHasPermission"
import { WebCacheCollections } from "store/cacheHelpers"

/** Hook to get the count of documents that match a given filter. */
export function useFirestoreCount<T extends Exclude<WebCacheCollections, "buildings">>({
    fetch = true,
    coll,
    additionalFilters,
    permissionKey,
    fetchInterval = 300,
    orderByName = "asc",
}: {
    /**
     * Whether to actually fetch the count. Useful to delay fetching until a condition is met.
     * @default true
     */
    fetch?: boolean
    /** The collection to fetch. */
    coll: T | undefined
    /** Filters on the collection. */
    additionalFilters?: QueryConstraint[]
    /** The permission to check before executing the query. */
    permissionKey?: PermissionKey
    /** The amount of seconds between each fetch from the db.
     * @default 300 seconds = 5 minutes
     */
    fetchInterval?: number
    /**
     * The order of the documents. Should match the order of the last `orderBy` in the `additionalFilter`.
     *
     * If `none`, we do not append a `orderBy("__name__")` clause. BE CAREFUL WHEN USING THIS.
     * @default "asc"
     */
    orderByName?: "asc" | "desc" | "none"
}) {
    const { buildingRef, fiscalYear } = useAppParams()
    const [count, setCount] = useState(0)
    const hasPermission = useHasPermission(permissionKey)

    const withFiscalYear = coll && ["units", "banks", "suppliers", "people"].includes(coll)
    const q = useMemo(
        () =>
            coll && buildingRef && hasPermission && fetch ?
                query(
                    collection(db, "buildings", buildingRef, coll),
                    ...(withFiscalYear ? [where("fiscalYear", "==", fiscalYear)] : []),
                    ...(additionalFilters ?? []),
                    // If we have no other filters, we do not append the orderBy("__name__") clause as it causes a bug.
                    ...(orderByName !== "none" && (withFiscalYear || additionalFilters?.length) ?
                        [orderBy("__name__", orderByName ?? "asc")]
                    :   [])
                )
            :   undefined,
        [
            coll,
            buildingRef,
            hasPermission,
            fetch,
            withFiscalYear,
            fiscalYear,
            additionalFilters,
            orderByName,
        ]
    )

    useEffect(() => {
        if (!q) return
        const handler = () =>
            getCountFromServer(q)
                .then((data) => setCount(data.data().count))
                .catch((error) =>
                    console.error(`An error came up while fetching the count:`, error)
                )
        handler()
        const timer = setInterval(handler, fetchInterval * 1000)
        return () => clearInterval(timer)
    }, [coll, q, fiscalYear, fetchInterval])

    return count
}
