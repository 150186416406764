/*
 * Modified version of https://github.com/kentcdodds/use-deep-compare-effect/blob/main/src/index.ts.
 */
import { useEffect, useMemo, useRef } from "react"
import { deepEqual } from "fast-equals"

/**
 * @param value the value to be memoized (usually a dependency list)
 * @returns a memoized version of the value as long as it remains deeply equal
 */
export function useDeepCompareMemoize<T>(value: T) {
    const ref = useRef<T>(value)
    const signalRef = useRef<number>(0)
    if (!deepEqual(value, ref.current)) {
        ref.current = value
        signalRef.current += 1
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => ref.current, [signalRef.current])
}

/** A wrapped useEffect function that uses a deep comparison on objects to see if they changed. */
export function useDeepCompareEffect(
    callback: Parameters<typeof useEffect>[0],
    dependencies: Parameters<typeof useEffect>[1]
) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, useDeepCompareMemoize(dependencies))
}
